.App {
  text-align: left;
}

hr {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.editpage-form-label {
  font-size: 80% !important;
  font-weight: 700;
}

.signup-checkbox {
  padding-top: 6px;
  font-size: 80% !important;
}

.edit-mode-checkboxes {
  padding-left: 6px;
}

.editpage-form-group {
  margin-bottom: 1px !important;
}

.table-single-biller-name {
  width: 250px !important; 
}
.table-single-biller-id {
  width: 90px !important; 
}
.table-single-biller-account-number {
  width: 200px !important; 
}
.table-single-biller-complete {
  width: 40px !important; 
}

.table-biller-name {
  padding-top: 8px;
  width: 250px;
  font-size: 80% !important;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display:inline-block;
}

.biller-table-div {
  padding: 2px;
  border: 1px solid #ccc;
  height: 100%;
}

.signup-details-div {
  padding: 2px;
}

.biller-table thead tr th {
  font-size: 80% !important;
  font-weight: 700;
  text-align: center;
}

.biller-table tbody tr td {
  padding-bottom: 1px;
  padding-top: 1px;
}

.navbar-spacer {
  height: 10px;
}

.navbar-brand-spacer {
  width: 30px;
}

.nav-link {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.navbar-save-buttons {
  padding-top: 6px;
}

.top-nav-tab.active {
  background-color: #ccc !important;
}

.section-spacer {
  height: 6px;
}

.body-div {
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.signup-controls {
  text-align: center;
}

.signup-navigator-text {
  display: inline-block;
  width: 120px;
  border: 1px solid #ccc;
  padding-left: 6px;
  text-align: left;
}

.signup-navigator-spacer {
  display: inline-block;
  width: 2px;
}

.signup-navigator-spacer-sm {
  display: inline-block;
  width: 1px;
}

.signup-control-spacer {
  display: inline-block;
  width: 8px;
}

.signin-changepassword-spacer {
  height: 8px;
}

.signin-newpassword-title {
  font-weight: 700;
  display: inline-block;
  padding-bottom: 8px;
}

.signin-newpassword-good {
  color: green;
}

.signin-newpassword-bad {
  color: #df4759;
}

.credentials-label {
  font-weight: 700; 
}

.biller-not-found {
  font-size: 108% !important;
}

.field-valid-icon {
  padding-top: 4px !important;
  font-size: 110% !important;
}

.field-valid-icon-good {
  padding-top: 4px !important;
  color: green;
}

.field-valid-icon-bad {
  padding-top: 4px !important;
  color: #df4759;
}

.field-valid-icon-notsure {
  padding-top: 4px !important;
  color: #ccc;
}

.signin-card {
  margin: 0 auto;
  max-width: 500px;
  min-width: 300px;
}

.brand-router {
  color: #111;
  text-decoration: none;

}

.nav-router {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  color: #111;
  text-decoration: none;
}

.nav-router:hover, .brand-router:hover {
  text-decoration: none;
  color: #111;
}

.nav-router-active {
  font-weight: 900; 
  border: 1px solid #111;
}

.table-headers-body-complete {
  text-align: center;
}

.table-banking-body-complete {
  text-align: center;
}
.table-banking-body-edit {
  text-align: center;
}

.table-details-body-edit {
  text-align: center;
}

.table-banking-header-acct-number {
  width: 150px;
}

.table-banking-header-routing-number {
  width: 150px;
}

.table-headers-body-form-operator {
  text-align: center;
}

.table-banking-body-form-operator {
  text-align: center;
}

.table-banking-body-form-id {
  text-align: center;
}
.table-banking-body-form-source {
  text-align: center;
}

.table-details-body-form-id {
  text-align: center;
}
.table-details-body-form-source {
  text-align: center;
}
.table-details-body-form-operator {
  text-align: center;
}

.table-details-body-biller-missing {
  text-align: center;
}

.table-details-header-edit {
  text-align: center;
}

.table-details-body-biller-complete {
  text-align: center;
}

.navbar-form {
  background: green;
  text-align: left;
}

.navbar-view-switcher {
  padding-left: 24px;
  padding-right: 24px;
}

.navbar-form-button-spacer {
  width: 12px;
}

.create-signupset-button-div {
  padding-top: 40px;
}

.signup-single-errors-ul {
  padding-left: 18px;
}

.zero-signups-alert {
  width: 400px;
  margin: 0 auto;
}

.zero-signups-div {
  padding-top: 30px;
  padding-bottom: 30px;
}

.signup-banking-finames-div {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  padding: 0;
  margin: 0;
}

.signup-banking-finames-clear-filter {
  border: 1px solid #ccc !important;
}